<template>
  <v-card elevation="0" light>
    <v-card-text>
      <content-section :title="$t('app.ContentCardComponent.whoami')">
        <v-row>
          <v-col cols="12" md="12">{{ $store.state.cv.whoami }}</v-col>
        </v-row>
      </content-section>
      <content-section
        v-if="$store.state.cv.certification"
        :title="$t('app.ContentCardComponent.certification')"
      >
        <v-row
          v-for="(certificate, i) in $store.state.cv.certification"
          :key="i"
        >
          <v-col cols="12" md="4">{{ certificate.year }}</v-col>
          <v-col cols="12" md="8">
            <strong v-if="certificate.title">{{ certificate.title }}</strong>
          </v-col>
        </v-row>
      </content-section>
      <content-section
        v-if="$store.state.cv.educations"
        :title="$t('app.ContentCardComponent.education')"
      >
        <v-row v-for="(education, i) in $store.state.cv.educations" :key="i">
          <v-col cols="12" md="4"
            >{{ education.from }} - {{ education.to }}</v-col
          >
          <v-col cols="12" md="8">
            <strong v-if="education.title">{{ education.title }}</strong>
            <div v-if="education.location">
              <i>{{ education.location }}</i>
            </div>
            <div v-if="education.description">
              {{ education.description }}
            </div>
          </v-col>
        </v-row>
      </content-section>
      <content-section
        v-if="$store.state.cv.technologies"
        :title="$t('app.ContentCardComponent.technologies')"
      >
        <template>
          <v-row>
            <v-col
              v-for="(technologies, i) in $store.state.cv.technologies"
              :key="i"
              cols="12"
              md="6"
            >
              <v-icon v-if="technologies.icon">
                {{ technologies.icon }}
              </v-icon>
              {{ technologies.title }}
              <v-progress-linear height="3" :value="technologies.value" />
            </v-col>
          </v-row>
        </template>
      </content-section>
      <content-section
        v-if="$store.state.cv.products"
        :title="$t('app.ContentCardComponent.products')"
      >
        <template>
          <v-row>
            <v-col
              v-for="(product, i) in $store.state.cv.products"
              :key="i"
              cols="12"
              md="6"
            >
              <v-icon v-if="product.icon">
                {{ product.icon }}
              </v-icon>
              {{ product.title }}
              <v-progress-linear height="3" :value="product.value" />
            </v-col>
          </v-row>
        </template>
      </content-section>
    </v-card-text>
  </v-card>
</template>

<script>
import ContentSection from './ContentSection'
export default {
  name: 'ContentCardComponent',
  components: { ContentSection }
}
</script>
