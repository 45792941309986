<template>
  <v-row>
    <v-col v-for="(item, i) in items" :key="i" cols="12" md="12">
      <h3>{{ item.title }}</h3>
      <v-progress-linear :value="item.value" height="25" color="white">
        <strong>{{ item.description }}</strong>
      </v-progress-linear>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'InfoProgressComponent',
  props: { items: { type: Array, default: () => [] } }
}
</script>

<style lang="scss" scoped>
.theme--dark.v-progress-linear {
  color: black;
}
</style>
