<template>
  <v-chip class="ma-2" label>
    <v-icon left>
      {{ item.icon }}
    </v-icon>
    {{ item.text }}
  </v-chip>
</template>

<script>
export default {
  name: 'InfoChipComponent',
  props: { item: { type: Object, default: () => {} } }
}
</script>

<style lang="scss" scoped>
.theme--dark.v-chip:not(.v-chip--active) {
  background: white;
}
.theme--dark.v-chip {
  color: black;
}
.theme--dark.v-icon {
  color: black;
}
</style>
