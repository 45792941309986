<template>
  <div class="mb-4">
    <div class="title mb-3">
      {{ information.title }}
    </div>
    <div v-if="elementType === 'text'">
      <info-text-component
        v-for="(item, index) in information.items"
        :key="index"
        :item="item"
      />
    </div>
    <div v-if="elementType === 'link'">
      <info-link-component
        v-for="(item, index) in information.items"
        :key="index"
        :item="item"
      />
    </div>
    <div v-if="elementType === 'chip'">
      <info-chip-component
        v-for="(item, index) in information.items"
        :key="index"
        :item="item"
      />
    </div>
    <div v-if="elementType === 'progress'">
      <v-container pa-0>
        <v-layout>
          <info-progress-component :items="information.items" />
        </v-layout>
      </v-container>
    </div>
  </div>
</template>

<script>
import InfoTextComponent from './InfoTextComponent.vue'
import InfoChipComponent from './InfoChipComponent.vue'
import InfoLinkComponent from './InfoLinkComponent.vue'
import InfoProgressComponent from './InfoProgressComponent.vue'
export default {
  name: 'InfoSectionComponent',
  components: {
    InfoTextComponent,
    InfoChipComponent,
    InfoLinkComponent,
    InfoProgressComponent
  },
  props: {
    elementType: { type: String, default: () => 'text' },
    information: { type: Object, default: () => {} }
  }
}
</script>
