<template>
  <v-main>
    <v-card elevation="0" dark>
      <v-card-text>
        <info-avatar-component />
        <info-section-component
          :elementType="'text'"
          :information="$store.state.cv.info"
        />
        <info-section-component
          :elementType="'link'"
          :information="$store.state.cv.socials"
        />
        <info-section-component
          :elementType="'chip'"
          :information="$store.state.cv.hobbies"
        />
        <info-section-component
          :elementType="'progress'"
          :information="$store.state.cv.languages"
        />
      </v-card-text>
    </v-card>
  </v-main>
</template>

<script>
import InfoSectionComponent from './InfoSectionComponent.vue'
import InfoAvatarComponent from './InfoAvatarComponent.vue'
export default {
  name: 'InfoCardComponent',
  components: {
    InfoSectionComponent,
    InfoAvatarComponent
  }
}
</script>
