import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    cv: {},
    show: false
  },
  getters: {},
  mutations: {
    SET_CV(state, cv) {
      state.cv = cv
    },
    SET_SHOW(state, show) {
      state.show = show
    }
  },
  actions: {
    async loadData({ commit }) {
      const cv = await require('@/assets/cv.json')
      commit('SET_CV', cv)
      commit('SET_SHOW', true)
    }
  },
  modules: {}
})
