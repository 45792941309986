<template>
  <v-main>
    <v-timeline
      v-for="(job, jobIndex) in $store.state.cv.jobs"
      :key="'job_' + jobIndex"
      dense
    >
      <v-timeline-item class="mb-4" :color="job.color">
        <h1 :class="`font-weight-bold`">
          <a :href="job.url" target="_blank">
            {{ job.company }}
          </a>
        </h1>
      </v-timeline-item>
      <v-timeline-item
        v-for="(entry, entryIndex) in job.entries"
        :key="'job_entry_' + entryIndex"
        small
        class="py-4"
        :color="job.color"
      >
        <h2 :class="`headline font-weight-light mb-4 ${job.color}--text`">
          {{ entry.position }}
        </h2>
        <span :class="`font-weight-light mb-4 ${job.color}--text`">{{
          entry.from + ' - ' + entry.to
        }}</span>
        <div>
          {{ entry.description }}
        </div>
      </v-timeline-item>
    </v-timeline>
  </v-main>
</template>
<script>
export default {
  name: 'TimelineCardComponent',
  components: {}
}
</script>
<style lang="scss" scoped>
.v-timeline {
  background-color: rgb(249, 249, 249);
}
</style>
