<template>
  <v-row>
    <v-col cols="12" md="12" class="text-md-center pb-5">
      <v-avatar size="128" rounded>
        <img src="@/assets/avatar.png" />
      </v-avatar>
    </v-col>
    <v-col cols="12" md="12" class="text-md-center pb-15">
      <h1>{{ $store.state.cv.name }}</h1>
    </v-col>
  </v-row>
</template>

<script>
export default { name: 'InfoAvatarComponent' }
</script>
