<template>
  <v-list-item @click="goToLink">
    <v-list-item-icon>
      <v-icon v-text="item.icon"></v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title v-if="item.text">{{ item.text }}</v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: 'InfoLinkComponent',
  props: { item: { type: Object, default: () => {} } },
  methods: {
    goToLink() {
      if (this.item.link) {
        window.open(this.item.link)
      }
    }
  }
}
</script>
