<template>
  <v-container v-if="$store.state.show" transition="scale-transition">
    <v-row>
      <v-col cols="12" md="4"><info-card-component /></v-col>
      <v-col cols="12" md="8"><content-card-component /></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12"><timeline-card-component /></v-col>
    </v-row>
  </v-container>
</template>
<script>
import InfoCardComponent from '@/components/InfoCardComponent.vue'
import TimelineCardComponent from '@/components/TimelineCardComponent.vue'
import ContentCardComponent from '@/components/ContentCardComponent.vue'

export default {
  name: 'HomeView',
  components: {
    InfoCardComponent,
    TimelineCardComponent,
    ContentCardComponent
  },
  methods: {
    async loadData() {
      await this.$store.dispatch('loadData')
      this.$forceUpdate()
    }
  },
  created() {
    this.loadData()
  }
}
</script>
